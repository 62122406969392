<script lang="ts">
	import { db } from "./config";
	import goPrint from "./print";

	import { connection } from "./store";

	export let name: string;

	const storeId = '8QTrwPjFYQJuNmbZP';
	// const storeId = "iS2yzp5S5YJvY5kxJ"; //local

	let trans = [];

	connection.subscribe((server) => {
		(async () => {
			await server.connect();
			const transSub = server.subscribe(
				"transactions.findTodayByStore",
				storeId
			);
			let transCollection = server.collection("transactions").reactive();
			transCollection.onChange(async (data) => {
				console.log("changed on transactions");
				console.log(data);
				// trans = data;

				db.trans.toArray().then((savedData) => {
					const existingCodes = savedData.map((it) => it.code);
					const newest = data.filter(
						(it) => !existingCodes.includes(it.code)
					);
					if (newest.length > 0) {
						newest.forEach(async (it) => {
							await db.trans.put({ code: it.code, date: new Date() });

							//print here
							console.log("print", it);
							
							// setInterval(() => {
							// 	trans = newest.filter(
							// 		(itt) => itt.code != it.code
							// 	);
							// }, 1000);
						});
						trans = newest;
						goPrint(newest[0], (code) => {
							console.log('selesai print', code);
							setInterval(() => {
								trans = newest.filter(
									(itt) => itt.code != code
								);
							}, 1000);
						});
					}
				});
			});
		})();

		server.on("connected", () => {
			// do something
			console.log("connected");
		});
		server.on("disconnected", () => {
			// for example show alert to user
			console.log("disconnected");
		});
		server.on("error", (e) => {
			// global errors from server
			console.error("error:", e);
		});
	});
</script>

<main>
	<h1>Hello Bajawa!</h1>
	{#each trans as item}
		<p>{item.code}</p>
	{/each}
</main>

<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 2em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>
